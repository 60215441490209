// ** React Imports
import {Fragment, lazy, Suspense, useState} from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import {Provider} from 'react-redux'
import {store} from '@store/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import {Slide, toast, ToastContainer} from 'react-toastify'
import {ThemeContext} from './utility/context/ThemeColors'
import {IntlProviderWrapper} from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** React Multi Select styles
import "@kenshooui/react-multi-select/dist/style.css"

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import Api from "./Api"
import watch from "redux-watch"
import {isUserLoggedIn} from "./utility/Utils"
import {useUpdateAppData} from "./utility/hooks/useUpdateAppData"
import Avatar from "./@core/components/avatar"
import {Bell, Check} from "react-feather"
import {lang} from "@src/utility/Utils"

import 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

window.api = new Api()

window.toast = {}

window.toast.success = function (message) {
  toast.success(
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='success' icon={<Check/>}/>
          <h6 className='toast-title'>{lang('SUCCESS!')}</h6>
        </div>
      </div>
      <div className='toastify-body'>
        <span>{message}</span>
      </div>
    </Fragment>,
    {transition: Slide, hideProgressBar: true, autoClose: 2000}
  )
}

window.toast.error = function (message) {
  toast.success(
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='danger' icon={<Bell/>}/>
          <h6 className='toast-title text-danger'>{lang('ERROR!')}</h6>
        </div>
      </div>
      <div className='toastify-body'>
        <span>{message}</span>
      </div>
    </Fragment>,
    {transition: Slide, hideProgressBar: true, autoClose: 2000}
  )
}

function Index() {
  const [appData, setAppData] = useState(null)
  const w = watch(store.getState, 'appData')
  const unsubscribe = store.subscribe(
    w((newVal, oldVal, selector) => {
      setAppData(store.getState().appData)
      unsubscribe() // if you don't unsubscribe it causes memory leak
    })
  )
  const updateAppData = useUpdateAppData()

  if (isUserLoggedIn() && appData === null) {
    updateAppData()

    if (appData === null) {
      return <span/>
    } else {
      return <Suspense fallback={<Spinner/>}>
        <ThemeContext>
          <IntlProviderWrapper>
            <LazyApp/>
            <ToastContainer newestOnTop/>
          </IntlProviderWrapper>
        </ThemeContext>
      </Suspense>
    }
  }

  // TODO Make code review in here.
  return <Suspense fallback={<Spinner/>}>
    <ThemeContext>
      <IntlProviderWrapper>
        <LazyApp/>
        <ToastContainer newestOnTop/>
      </IntlProviderWrapper>
    </ThemeContext>
  </Suspense>
}

ReactDOM.render(
  <Provider store={store}>
    <Index/>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
