// **  Initial State
const initialState = {
  token: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        token: action.token
      }
    case 'LOGOUT':
      const obj = {...action}
      delete obj.type
      return {...state, ...obj, token: null}
    default:
      return state
  }
}

export default authReducer
