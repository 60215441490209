import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

/**
 * API request class
 */
class Api {

  /**
   * Return API URL
   * @returns {string}
   */
  getApiUrl() {
    const apiUrl = window.env.REACT_APP_API_ENDPOINT
    return apiUrl
  }

  /**
   * Set bearer token for axios.
   * @returns {{headers: {Authorization: string, "Content-Type": string}, validateStatus: (function(*))}}
   */
  getLoggedInConfig() {

    const self = this
    const token = localStorage.getItem('token')
    const selectedLang = localStorage.getItem('selectedLang') ? `${localStorage.getItem('selectedLang')}; ${navigator.language}` : navigator.language

    if (token.length < 32) {
      // TODO Think about this. Can you find a better way for redirecting user to login page?
      window.location.hash = "login"

      setTimeout(function () {
        window.location.reload(true)
      }, 5)

      return null
    }

    return {
      validateStatus: (status) => status >= 200 && status <= 500,
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': selectedLang
      }
    }
  }

  /**
   * Not logged in config for axios.
   * @returns {{headers: {"Content-Type": string}, validateStatus: (function(*))}}
   */
  getGeneralConfig() {
    const self = this
    const selectedLang = localStorage.getItem('selectedLang') ? `${localStorage.getItem('selectedLang')}; ${navigator.language}` : navigator.language

    return {
      validateStatus: (status) => status >= 200 && status <= 500,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        'Accept-Language': selectedLang
      }
    }
  }

  /**
   * Configure axios for posting file.
   * @param progressCb
   * @returns {{headers: {Authorization: string, "Content-Type": string}, validateStatus: (function(*)), onUploadProgress: AxiosRequestConfig.onUploadProgress}|null}
   */
  getFileUploadConfig(progressCb) {
    const self = this
    const token = localStorage.getItem('token')
    const selectedLang = localStorage.getItem('selectedLang') ? `${localStorage.getItem('selectedLang')}; ${navigator.language}` : navigator.language

    if (token.length < 32) {
      // TODO handle not logged in state
      return null
    }

    return {
      validateStatus: (status) => status >= 200 && status <= 500,
      onUploadProgress: data => {
        console.log(data)

        progressCb(data)
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        'Accept-Language': selectedLang
      }
    }
  }

  /**
   * Check ajax response, if service not responded then showing a sweetalert.
   * @param result
   * @returns {*|Promise<SweetAlertResult<Awaited<any>>>}
   */
  handleAjaxResponse(result) {
    if (typeof result === "undefined") {
      return
    }

    // we can access response from result.data
    if (typeof result.data === "undefined") {
      return MySwal.fire(
        'ERROR',
        'Can not access to service.',
        'error'
      )
    }

    if (typeof result.data.errors !== "undefined") {
      if (result.data.errors[0] === "Invalid Token") {
        window.location.href = "login"
      }
    }

    return result
  }

  /**
   * Handle file upload.
   * @param command
   * @param postData
   * @param progressCb
   * @returns {Promise<AxiosResponse<any>>}
   */
  upload(command, postData, progressCb) {
    const self = this

    return axios
      .post(`${self.getApiUrl()}/${command}`, postData, self.getFileUploadConfig(progressCb))
      .then(function (result) {
        return self.handleAjaxResponse(result)
      })
      .catch(function (result) {
        return self.handleAjaxResponse(result)
      })
  }

  /**
   * Post json data.
   * @param command
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  post(command, data) {
    const self = this

    return axios
      .post(`${self.getApiUrl()}/${command}`, data, self.getLoggedInConfig())
      .then(function (result) {
        return self.handleAjaxResponse(result)
      })
      .catch(function (result) {
        return self.handleAjaxResponse(result)
      })
  }

  /**
   * Create GET request and return a Promise.
   * @param command
   * @returns {Promise<AxiosResponse<any>>}
   */
  get(command) {
    const self = this

    return axios
      .get(`${self.getApiUrl()}/${command}`, self.getLoggedInConfig())
      .then(function (result) {
        return self.handleAjaxResponse(result)
      })
      .catch(function (result) {
        return self.handleAjaxResponse(result)
      })
  }

  /**
   * Make login request and store the token.
   * @param email
   * @param password
   * @returns {Promise<AxiosResponse<any>>}
   */
  login(email, password) {
    const self = this
    const jsonObj = {}
    jsonObj.email = email
    jsonObj.password = password

    return axios
      .post(`${self.getApiUrl()}/auth/login`, jsonObj, self.getGeneralConfig())
      .then(function (result) {
        return self.handleAjaxResponse(result)
      })
      .catch(function (result) {
        return self.handleAjaxResponse(result)
      })
  }

  /**
   * Make register request and store the token.
   * @returns {Promise<AxiosResponse<any>>}
   */
  register() {
    // TODO Fill this method.
    console.log(arguments)
    return

    const self = this
    const jsonObj = {}
    jsonObj.email = email
    jsonObj.password = password

    return axios
      .post(`${self.getApiUrl()}/auth/register`, jsonObj, self.getGeneralConfig())
      .then(function (result) {
        return self.handleAjaxResponse(result)
      })
      .catch(function (result) {
        return self.handleAjaxResponse(result)
      })
  }

  /**
   * Make unauthenticated request and get internationalization data.
   * @returns {Promise<AxiosResponse<any>>}
   */
  loadLanguages() {
    const self = this
    return axios
      .get(`${self.getApiUrl()}/languages`, self.getGeneralConfig())
      .then(function (result) {
        return self.handleAjaxResponse(result)
      })
      .catch(function (result) {
        return self.handleAjaxResponse(result)
      })
  }
}

export default Api
